import { sortByField } from '../../utils/sortByField';
import * as permissions from '../../firewall/permissions';

/* eslint-disable camelcase */
const mapLookerIdsToReactRouterUrl = {
  order_status_overview: '/analytics/orders/status-overview',
  order_fulfillment_performance: '/analytics/orders/fulfillment-performance',
  order_list: '/analytics/orders/list',
  days_from_order_status_changed_outliers: '/analytics/orders/days-from-status-changed-outliers',
  returns_overview: '/analytics/shipments/returns-overview',
  claims_overview: '/analytics/claims/claims-overview',
  shipment_status_overview: '/analytics/shipments/status-overview',
  shipment_list: '/analytics/shipments/list',
  shipment_carrier_performance: '/analytics/shipments/carrier-performance',
  shipment_carrier_comparison: '/analytics/shipments/carrier-comparison',
  days_from_shipment_status_changed_outliers: '/analytics/shipments/days-from-status-changed-outliers',
};

const prefixDashboardsOrder = ['order', 'carrier', 'shipment', 'returns_overview', 'claims'];
function getOrderPosition(dashboard) {
  for (let i = 0; i < prefixDashboardsOrder.length; i++) {
    const prefix = prefixDashboardsOrder[i];
    if (dashboard.id.startsWith(prefix)) {
      return i;
    }
  }

  return 100;
}

export function buildSortedMenuItems(items) {
  return sortByField(items, [getOrderPosition, (x) => x.id], { asc: true }).map(({ id, name, type }) => ({
    id: mapLookerIdsToReactRouterUrl[id] || `/analytics/${type}s/${id}`,
    link: mapLookerIdsToReactRouterUrl[id] || `/analytics/${type}s/${id}`,
    permission: permissions.ANALYTICS_PERMISSION,
    title: name,
  }));
}
