import { useQuery } from '@tanstack/react-query';
import { useSelectedShopId } from '../../../../hooks/useSelectedShop';

import container from '../../../../container';

/**
 * @typedef ShopClaimCarrier
 * @property {number} id
 * @property {string} name
 * @property {string} code
 * @property {string} countryName
 * @property {string} countryCode
 * @property {boolean} isNon7s
 */

/**
 * @return {import('@tanstack/react-query').UseQueryResult<ShopClaimCarrier>}
 */
export function useClaimsShopCarriers() {
  const shopId = useSelectedShopId();

  return useQuery({
    queryKey: ['shopClaimsCarriers', shopId],
    queryFn: () => container.touchpointsApi.getClaimsShopCarriers(shopId),
    refetchOnWindowFocus: false,
  });
}
