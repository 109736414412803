import React, { useState } from 'react';
import PropTypes from 'prop-types';

import BackButton from './BackButton';
import Button from '../../../common2/Button';
import ExitClaimsModal from '../ExitClaimsModal';
import { generateHeapId } from '../../../../heap';
import SidePanelContainer from '../../../common2/side-panel/SidePanelContainer';
import SidePanelHeader from '../../../common2/side-panel/SidePanelHeader';
import SidePanelContent from '../../../common2/side-panel/SidePanelContent';
import SidePanelFooter from '../../../common2/side-panel/SidePanelFooter';
import Stretch from '../../../common/layout/Stretch';

function ClaimStepContainer(props) {
  const {
    subHeading,
    onBack,
    onNext,
    onClose,
    isSubmitButton = false,
    isDisabled,
    confirmExit,
    children,
    isSubmitting,
    name,
  } = props;

  const nextLabel = isSubmitButton ? 'Submit claim' : 'Next';
  const nextButtonName = generateHeapId(`${name} ${nextLabel}`);
  const backButtonName = generateHeapId(`${name} back`);

  const [isExitModalShown, setExitModalShown] = useState(false);

  const onClickCloseButton = () => {
    if (confirmExit) {
      setExitModalShown(true);
    } else {
      onClose();
    }
  };

  return (
    <SidePanelContainer>
      <SidePanelHeader
        onClose={onClickCloseButton}
        heading="Submit a claim"
        helpLink="https://support.portal.sevensenders.com/support/solutions/folders/15000009247"
      >
        {subHeading}
      </SidePanelHeader>
      <SidePanelContent>{children}</SidePanelContent>
      <SidePanelFooter>
        {onBack && <BackButton onClick={onBack} name={backButtonName} />}
        <Stretch />
        {onNext && (
          <Button onClick={onNext} disabled={isDisabled || isSubmitting} isLoading={isSubmitting} name={nextButtonName}>
            {nextLabel}
          </Button>
        )}
      </SidePanelFooter>
      <ExitClaimsModal show={isExitModalShown} onExit={onClose} onCancel={() => setExitModalShown(false)} />
    </SidePanelContainer>
  );
}

ClaimStepContainer.propTypes = {
  subHeading: PropTypes.string,
  confirmExit: PropTypes.bool,
  onClose: PropTypes.func,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  isSubmitButton: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

export default React.memo(ClaimStepContainer);
