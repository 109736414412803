export const claimTypeOptions = [
  { label: 'Investigation', value: 'investigation' },
  { label: 'Partial damage', value: 'partial_damage' },
  { label: 'Whole damage', value: 'whole_damage' },
  { label: 'Missing item', value: 'missing_item' },
];

export const claimStatusOptions = [
  { label: 'Submitted', value: 'submitted' },
  { label: 'Resubmitted', value: 'resubmitted' },
  { label: 'Action required', value: 'action_required' },
  { label: 'In progress', value: 'in_progress' },
  { label: 'Accepted', value: 'accepted' },
  { label: 'Credit note pending', value: 'credit_note_pending' },
  { label: 'Successful', value: 'successful' },
  { label: 'Declined', value: 'declined' },
  { label: 'Escalating', value: 'escalating' },
  { label: 'Payment overdue', value: 'payment_overdue' },
  { label: 'Incorrect payment', value: 'incorrect_payment' },
  { label: 'Paid', value: 'paid' },
];

export const claimShipmentTypesOptions = [
  { label: 'Seven Senders', value: '7s' },
  { label: 'Non Seven Senders', value: 'non-7s' },
];

/* eslint-disable camelcase */
export const claimStatusCountMap = {
  accepted: 'acceptedCount',
  action_required: 'actionRequiredCount',
  credit_note_pending: 'creditNotePendingCount',
  declined: 'declinedCount',
  in_progress: 'inProgressCount',
  resubmitted: 'resubmittedCount',
  submitted: 'submittedCount',
  successful: 'successfulCount',
  escalating: 'escalatingCount',
  payment_overdue: 'paymentOverdueCount',
  incorrect_payment: 'incorrectPaymentCount',
  paid: 'paidCount',
};
/* eslint-enable camelcase */
