import React from 'react';
import PropTypes from 'prop-types';
import RadioButton from './RadioButton';

import './RadioGroup.scss';

class RadioGroup extends React.PureComponent {
  render() {
    const { name, options = [], value, onChange, children } = this.props;

    return (
      <ul styleName="list">
        {React.Children.map(children, (button) => (
          <li key={button.props.value}>{button}</li>
        ))}
        {options.map((item) => (
          <li key={item.value} data-heap-id={item.value}>
            <RadioButton
              name={name}
              onChange={onChange}
              value={item.value}
              label={item.label}
              disabled={item.disabled}
              checked={item.value === value}
            />
          </li>
        ))}
      </ul>
    );
  }
}

// You can pass array of options of use RadioButton components as children (declarative way)

RadioGroup.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
};

export default RadioGroup;
