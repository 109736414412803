import container from '../container';
import { submitClaimRequest, submitClaimSuccess, submitClaimFailure } from '../reducers/claimReducer';
import { parcelFinderReload } from '../reducers/parcelFinderReducer';

const claimDataNormalizers = {
  investigation: prepareInvestigationClaimData,
  'whole-damage': prepareWholeDamageClaimData,
  'partial-damage': preparePartialDamageClaimData,
  'missing-item': prepareMissingItemClaimData,
};

function submitClaim({ isClaimsAutomated }) {
  return (dispatch, getState) => {
    dispatch(submitClaimRequest());

    const { claim, shipment } = getState().claim;

    if (isClaimsAutomated) {
      return container.touchpointsApi
        .submitClaimAutomated(claim.type, normalizeClaimData(claim, shipment))
        .then(() => {
          dispatch(submitClaimSuccess());
          dispatch(parcelFinderReload());
          return { success: true };
        })
        .catch(() => {
          dispatch(submitClaimFailure());
          return { success: false };
        });
    }

    return container.touchpointsApi
      .submitClaim(claim.type, normalizeClaimData(claim))
      .then(() => {
        dispatch(submitClaimSuccess());
        dispatch(parcelFinderReload());
        return { success: true };
      })
      .catch(() => {
        dispatch(submitClaimFailure());
        return { success: false };
      });
  };
}

export function normalizeClaimData({ type, ...claim }) {
  const normalizer = claimDataNormalizers[type];

  if (!normalizer) {
    throw new Error(`Doesn't support claim type "${type}"`);
  }

  return normalizer(claim);
}

function prepareBasicClaimData(claim) {
  const {
    shipmentId,
    isReturnShipment,
    trackingCode,
    customerName: recipientName,
    weight,
    netValue,
    netValueCurrency,
    trackingScreenshot,
    invoiceDocument,
    deliveryDate,
    postalReturnDeliveryDate,
    recipientAddress,
    recipientCity,
    recipientZipCode,
    ssHubScanDate,
    firstHubScanDate,
    carrierCode,
    carrierCountryCode,
    customerComment,
    shopOrderId,
  } = claim;

  return {
    shipmentId,
    isReturnShipment,
    trackingCode,
    carrierCode,
    carrierCountryCode,
    firstHubScanDate,
    ssHubScanDate,
    recipientName,
    weight: parseFloat(weight),
    deliveryDate: deliveryDate || undefined,
    postalReturnDeliveryDate: postalReturnDeliveryDate || undefined,
    netValue: Math.floor(parseFloat(netValue) * 100),
    netValueCurrency,
    trackingScreenshot: getFileId(trackingScreenshot),
    invoiceDocument: getFileId(invoiceDocument),
    customerComment,
    recipientAddress,
    recipientCity,
    recipientZipCode,
    shopOrderId,
  };
}

export function prepareInvestigationClaimData(claim) {
  return {
    ...prepareBasicClaimData(claim),
    declarationOfRecipient: getFileId(claim.declarationOfRecipient),
  };
}

function prepareDamageClaimData(claim) {
  return {
    ...prepareBasicClaimData(claim),
    picturesOfDamage: getFileList(claim.picturesOfDamage),
    warehouseDeliveryDate: claim.warehouseDeliveryDate || undefined,
  };
}

export function prepareWholeDamageClaimData(claim) {
  return {
    ...prepareDamageClaimData(claim),
    damageDescription: claim.wholeDamageInfo,
  };
}

export function preparePartialDamageClaimData(claim) {
  return {
    ...prepareDamageClaimData(claim),
    damageDescription: serializeItemDescription(claim.partialDamageInfo),
  };
}

export function prepareMissingItemClaimData(claim) {
  return {
    ...prepareBasicClaimData(claim),
    picturesOfMissingItem: getFileList(claim.picturesOfMissingItem),
    missingItemDescription: serializeItemDescription(claim.missingItemInfo),
    warehouseDeliveryDate: claim.warehouseDeliveryDate || undefined,
  };
}

function getFileId(data) {
  // FIX: "fileId" is deprecated use "id" instead
  return data?.[0]?.response?.body?.fileId;
}

function getFileList(data) {
  return data?.map?.((x) => x.response?.body?.fileId);
}

function serializeItemDescription(desc) {
  const strings = desc.map(
    (item) => `${item.amount}x ${item.itemName}${!!item.damageDescription ? `, ${item.damageDescription}` : ''}`
  );
  return strings.join('; ');
}

export default submitClaim;
