import React from 'react';
import PropTypes from 'prop-types';

import OptionsList from './OptionsList';
import PlainButton from '../../common/PlainButton';

import './FilterMenu.scss';
function FilterMenu(props) {
  const { onChange, options, selectedValues, onApply, isApplyDisabled, className } = props;

  return (
    <div styleName="dropdown" className={className}>
      <OptionsList options={options} selectedValues={selectedValues} onChange={onChange} />
      <PlainButton name="applyFilter" tabIndex={-1} disabled={isApplyDisabled} onClick={onApply}>
        Apply
      </PlainButton>
    </div>
  );
}

FilterMenu.propTypes = {
  onChange: PropTypes.func,
  onApply: PropTypes.func,
  className: PropTypes.string,
  isApplyDisabled: PropTypes.bool,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
};

export default React.memo(FilterMenu);
