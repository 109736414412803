import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Input from '../../../common2/Input';
import DateInput from '../../../common2/DateInput';
import ColumnsLayout from '../../../common/layout/ColumnsLayout';
import Column from '../../../common/layout/Column';
import WeightInput from './WeightInput';
import Uploader from '../../../common/Uploader/Uploader';
import Select from '../../../common2/Select';
import InputHelpLink from '../InputHelpLink';
import ClaimStepClosableMessage from './ClaimStepClosableMessage';
import Locale from '../../../Translate/Locale';

function ShippingInformation(props) {
  const {
    claim: {
      trackingCode,
      carrierCountryCode,
      customerName,
      recipientAddress,
      recipientCity,
      recipientZipCode,
      carrierCode,
      firstHubScanDate,
      ssHubScanDate,
      deliveryDate,
      warehouseDeliveryDate,
      weight,
      trackingScreenshot,
      type,
      postalReturnDeliveryDate,
    },
    carrierOptions = [],
    carrierCountryOptions = [],
    errorsWithTranslation = {},
    showDeliveryInfo,
    onChange,
    onBusy,
    noTrackingScreenshotRequiredMessage,
    isAllFieldsEditable,
    isInvestigationClaimPastDeadline,
    isDamageOrMissingItemClaimPastDeadline,
  } = props;

  const onInputChange = useCallback((value, name) => onChange({ [name]: value }), [onChange]);
  // To reset carrier country after each carrier code change
  const onCarrierCodeChange = useCallback((value, name) => onChange({ [name]: value, carrierCountryCode: null }), [
    onChange,
  ]);

  const isFieldDisabled = !isAllFieldsEditable;
  const isDeliveryDateRequired = type !== 'investigation';
  const showDeliveryDate = !!deliveryDate || !postalReturnDeliveryDate;
  const showWarehouseDeliveryDate = !!warehouseDeliveryDate;

  const uploaderHelpLinkElement = (
    // eslint-disable-next-line max-len
    <InputHelpLink to="https://support.portal.sevensenders.com/support/solutions/articles/15000048062-submission-document-requirements#Tracking-Screen-Shot-Requirements%C2%A0">
      Tracking screenshot requirements
    </InputHelpLink>
  );

  return (
    <Column spacing="medium">
      <ColumnsLayout>
        <Input
          name="trackingCode"
          label="SHIPPING_INFO_TRACKING_ID"
          placeholder="Add tracking ID"
          isRequired
          value={trackingCode}
          error={errorsWithTranslation.trackingCode}
          disabled={isFieldDisabled}
          onChange={onInputChange}
        />
        <div />
        <Select
          name="carrierCode"
          label="SHIPPING_INFO_CARRIER"
          placeholder="Select carrier"
          isRequired
          isSearchable
          noOptionTranslation
          value={carrierCode}
          options={carrierOptions}
          error={errorsWithTranslation.carrierCode}
          isDisabled={isAllFieldsEditable ? carrierOptions.length < 1 : isFieldDisabled}
          onChange={onCarrierCodeChange}
        />
        <Select
          name="carrierCountryCode"
          label="Carrier country"
          placeholder="Select country"
          isRequired
          isSearchable
          noOptionTranslation
          value={carrierCountryCode}
          options={carrierCountryOptions}
          error={errorsWithTranslation.carrierCountryCode}
          isDisabled={!carrierCode || isFieldDisabled}
          onChange={onInputChange}
        />
        <DateInput
          name="firstHubScanDate"
          label="First hub scan"
          placeholder="Add date"
          isRequired
          isWarned={isInvestigationClaimPastDeadline}
          value={firstHubScanDate || ssHubScanDate}
          error={errorsWithTranslation.firstHubScanDate || errorsWithTranslation.ssHubScanDate}
          disabled={isFieldDisabled}
          onChange={onInputChange}
        />
        {showWarehouseDeliveryDate ? (
          <DateInput
            name="warehouseDeliveryDate"
            label={'Warehouse delivery date'}
            isWarned={isDamageOrMissingItemClaimPastDeadline}
            value={warehouseDeliveryDate}
            disabled={true}
            isRequired={true}
          />
        ) : showDeliveryInfo ? (
          <DateInput
            name="deliveryDate"
            label={showDeliveryDate ? 'Delivery date' : 'Postal return delivery date'}
            placeholder="Add delivery date"
            value={showDeliveryDate ? deliveryDate : postalReturnDeliveryDate}
            onChange={onInputChange}
            isRequired={isDeliveryDateRequired}
            isWarned={isDamageOrMissingItemClaimPastDeadline}
            error={errorsWithTranslation.deliveryDate}
          />
        ) : (
          <div />
        )}
        <Input
          name="customerName"
          label="Recipient name"
          placeholder="Add name"
          isRequired
          value={customerName}
          error={errorsWithTranslation.customerName}
          onChange={onInputChange}
          noErrorTranslation
          disabled={isFieldDisabled}
        />
        <Input
          name="recipientAddress"
          label="Recipient address"
          placeholder="Add address"
          isRequired
          value={recipientAddress}
          error={errorsWithTranslation.recipientAddress}
          onChange={onInputChange}
          noErrorTranslation
          disabled={isFieldDisabled}
        />
        <Input
          name="recipientZipCode"
          label="Recipient zip code"
          placeholder="Add zip code"
          isRequired
          value={recipientZipCode}
          error={errorsWithTranslation.recipientZipCode}
          onChange={onInputChange}
          noErrorTranslation
          disabled={isFieldDisabled}
        />
        <Input
          name="recipientCity"
          label="Recipient city"
          placeholder="Add city"
          isRequired
          value={recipientCity}
          error={errorsWithTranslation.recipientCity}
          onChange={onInputChange}
          noErrorTranslation
          disabled={isFieldDisabled}
        />
        <WeightInput
          name="weight"
          label="Weight"
          value={weight}
          error={errorsWithTranslation.weight}
          onChange={onInputChange}
          noErrorTranslation
          isRequired
        />
      </ColumnsLayout>
      {noTrackingScreenshotRequiredMessage ? (
        <ClaimStepClosableMessage intent="success">
          <Locale>{noTrackingScreenshotRequiredMessage}</Locale>
        </ClaimStepClosableMessage>
      ) : (
        <Uploader
          name="trackingScreenshot"
          label="Tracking screenshot"
          topRightElement={uploaderHelpLinkElement}
          allowedFileTypes={['image/png', 'image/jpg', 'image/jpeg']}
          helpText="CLAIMS_SCREENSHOT_UPLOAD_HELP_TEXT"
          isRequired
          files={trackingScreenshot}
          errorWithTranslation={errorsWithTranslation.trackingScreenshot}
          onChange={onInputChange}
          onBusy={onBusy}
        />
      )}
    </Column>
  );
}

ShippingInformation.propTypes = {
  claim: PropTypes.shape({
    trackingCode: PropTypes.string,
    carrierCountryCode: PropTypes.string,
    customerName: PropTypes.string,
    fhsDate: PropTypes.object,
    sshsDate: PropTypes.object,
    deliveryDate: PropTypes.object,
    postalReturnDeliveryDate: PropTypes.object,
    deliveryAttemptDate: PropTypes.object,
    trackingScreenshot: PropTypes.array,
    weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
  }).isRequired,
  errorsWithTranslation: PropTypes.object,
  onChange: PropTypes.func,
  onBusy: PropTypes.func,
  noTrackingScreenshotRequiredMessage: PropTypes.string,
  isAllFieldsEditable: PropTypes.bool,
};

export default React.memo(ShippingInformation);
