import React from 'react';

import Uploader from '../../../common/Uploader';

function ItemPicturesUploader(props) {
  const { name, label, helpText, claim, errors, onChange, onBusy, uploaderHelpLinkElement } = props;

  return (
    <Uploader
      name={name}
      files={claim[name]}
      errorWithTranslation={errors[name]}
      allowedFileTypes={['image/png', 'image/jpg', 'image/jpeg']}
      maxNumberOfFiles={10}
      topRightElement={uploaderHelpLinkElement}
      label={label}
      isRequired
      helpText={helpText}
      onChange={onChange}
      onBusy={onBusy}
    />
  );
}

export default React.memo(ItemPicturesUploader);
