import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Text from '../../../common/Text';
import Locale from '../../../Translate/Locale';
import { generateHeapId } from '../../../../heap';
import TypeButtonWarningMessage from './TypeButtonWarningMessage';

import './TypeButton.scss';

function TypeButton(props) {
  const { type = 'primary', icon: Icon, title, description, warningMessage = '', onClick, disabled } = props;

  const buttonName = generateHeapId(`${title}`);

  return (
    <div styleName="container">
      <button styleName="button" onClick={onClick} disabled={disabled} name={buttonName}>
        <div styleName={classNames('icon-circle', type)}>
          <Icon styleName="icon" />
        </div>
        <Text ink>
          <Locale>{title}</Locale>
        </Text>
        <Text styleName="description">
          <Locale>{description}</Locale>
        </Text>
      </button>
      {disabled && <TypeButtonWarningMessage>{warningMessage}</TypeButtonWarningMessage>}
    </div>
  );
}

TypeButton.propTypes = {
  type: PropTypes.oneOf(['primary', 'warning', 'danger']),
  icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default React.memo(TypeButton);
