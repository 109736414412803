import React from 'react';
import PropTypes from 'prop-types';

import ItemInfoUnit from './ItemInfoUnit';
import PlainButton from '../../../../common/PlainButton';
import Text from '../../../../common/Text';
import Locale from '../../../../Translate/Locale';
import ValidationError from '../../../../common/ValidationError';
import { createEmptyInfoItem } from '../../claimsHelpers';
import { isEmptyValidationResult } from '../../claimValidators';

import AddIcon from '../../../../../icons/add.svg';

import './ItemInfo.scss';

function ItemInfo(props) {
  const { data, emptyInfoErrorText, hasDescription, errorsWithTranslation = {}, onChange } = props;

  const onAddButtonClick = () => onChange([...data, createEmptyInfoItem()]);

  const onRemoveButtonClick = (removeIndex) => {
    const newData = data.filter((_, index) => index !== removeIndex);
    onChange(newData);
  };

  const onChangeItem = (index, patch) => {
    const newData = data.slice();
    newData[index] = { ...newData[index], ...patch };
    onChange(newData);
  };

  const hasRemoveButtons = data.length > 1;

  return (
    <div styleName="container">
      <table styleName="table">
        <thead>
          <tr>
            <th styleName="amount">
              <Text ink>
                <Locale>Amount</Locale>
              </Text>
            </th>
            <th styleName="name">
              <Text ink>
                <Locale>Item name</Locale>
                {' *'}
              </Text>
            </th>
            {hasDescription && (
              <th styleName="description">
                <Text ink>
                  <Locale>Damage description</Locale>
                  {' *'}
                </Text>
              </th>
            )}
            {hasRemoveButtons && <th styleName="button" />}
          </tr>
        </thead>
        <tbody>
          {data.map((info, index) => (
            <ItemInfoUnit
              key={info.id}
              info={info}
              errors={errorsWithTranslation[info.id]}
              hasRemoveButton={hasRemoveButtons}
              hasDescription={hasDescription}
              onChange={(data) => onChangeItem(index, data)}
              onRemoveClick={() => onRemoveButtonClick(index)}
            />
          ))}
        </tbody>
      </table>
      {!isEmptyValidationResult(errorsWithTranslation) && (
        <div styleName="error">
          <ValidationError>
            <Locale>{emptyInfoErrorText}</Locale>
          </ValidationError>
        </div>
      )}
      <PlainButton icon={AddIcon} onClick={onAddButtonClick}>
        Add item
      </PlainButton>
    </div>
  );
}

ItemInfo.propTypes = {
  errorsWithTranslation: PropTypes.object,
  hasDescription: PropTypes.bool,
  emptyInfoErrorText: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.any),
};

export default React.memo(ItemInfo);
