import React from 'react';
import Label from '../Label';
import CircleIcon from '../../../icons/radio-circle.svg';

import './RadioButton.scss';

const RadioButton = (props) => (
  <Label inlineRight disabled={props.disabled}>
    <input
      type="radio"
      styleName="radio"
      {...props}
    />
    <div styleName="indicator">
      <CircleIcon styleName="icon" />
    </div>
    {props.label}
  </Label>
);

export default RadioButton;