import React from 'react';
import { connect } from 'react-redux';
import Iframe from '../common/Iframe';

import { fetchProxyToken } from '../../actions/proxyActions';
import {selectedShopIdSelector} from "../../reducers/selectors";

const analyticsBaseUrl = process.env.ANALYTICS_URL;

export class ProxyIframe extends React.PureComponent {
  state = {};

  componentDidMount() {
    if (!this.tokenIsValid()) {
      this.fetchToken();
    }
    window.addEventListener('message', this.onMessage, false);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onMessage, false);
  }

  onMessage = e => {
    if (e.data && e.data.type === 'analyticsRedirect') {
      this.setState({iframeIsLoaded: false});
    }
  };

  tokenIsValid() {
    const { token, expired } = this.props;
    return token && expired && (expired > (Date.now() / 1000));
  }

  fetchToken() {
    const {
      isLoading,
      error,
      fetchProxyToken,
    } = this.props;

    if (!isLoading && !error) {
      fetchProxyToken();
    }
  }

  getAnalyticsPageUrl() {
    const { pageUrl, shopId, token } = this.props;
    return `${analyticsBaseUrl}${pageUrl}?proxy_token=${token}&sendwiseShopId=${shopId}`
  }

  onLoad = () => {
    this.setState({iframeIsLoaded: true});
  };

  render() {
    const { token, isLoading } = this.props;
    const { iframeIsLoaded } = this.state;

    const showLoader = (!!token || isLoading) && !iframeIsLoaded;
    const showIframe = !!token && !isLoading;

    return (
      <Iframe
        onLoad={this.onLoad}
        pageUrl={this.getAnalyticsPageUrl()}
        showLoader={showLoader}
        showIframe={showIframe}
      />);
  }
}

function mapStateToProps(state) {
  const {
    proxy: {
      token,
      expired,
      isLoading,
      error,
    }
  } = state;

  const shopId = selectedShopIdSelector(state);

  return {
    shopId,
    token,
    expired,
    isLoading,
    error,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchProxyToken: () => dispatch(fetchProxyToken()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProxyIframe);