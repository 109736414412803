import React from 'react';
import PropTypes from 'prop-types';

import Locale from '../../Translate/Locale';
import Text from '../../common/Text';
import StatusBadge from '../../common/StatusBadge';
import PlainButton from '../../common/PlainButton';
import EventStatusBadge from '../EventStatusBadge';
import ClaimStatusBadge from '../ClaimStatusBadge/ClaimStatusBadge';
import ClaimTypeBadge from '../../claims-center/ClaimsTable/ClaimsTypeBadge';
import { generateHeapId } from '../../../heap';

import ViewIcon from '../../../icons/view.svg';

import './ResultHeader.scss';

function ResultHeader(props) {
  const {
    name,
    labelForId,
    id,
    lastEvent,
    link,
    children,
    showDetailsButton,
    claimStatus,
    showClaimedTag,
    claimType,
  } = props;

  const buttonName = generateHeapId(`${name} view details`);

  return (
    <div styleName="header">
      <div styleName="header-row">
        <StatusBadge neutral>{name}</StatusBadge>
        {id && (
          <React.Fragment>
            <Text styleName="label">
              <Locale>{labelForId}</Locale>:
            </Text>
            <Text styleName="id" ink>
              {id}
            </Text>
          </React.Fragment>
        )}
        <div styleName="details">
          {children && <div styleName="children">{children}</div>}
          {showDetailsButton && (
            <a styleName="link" href={link} target="_blank">
              <PlainButton icon={ViewIcon} name={buttonName}>
                View details
              </PlainButton>
            </a>
          )}
        </div>
      </div>
      <div styleName="status-row">
        {lastEvent && (
          <div styleName="status-container">
            <Text>
              <Locale>{name} status</Locale>
            </Text>
            <div styleName="status-badge">
              <EventStatusBadge meaning={lastEvent.meaning} type={lastEvent.type} />
            </div>
          </div>
        )}
        {showClaimedTag && (
          <div styleName="status-container">
            <Text>
              <Locale>Claim status</Locale>
            </Text>
            <div styleName="status-badge">
              <ClaimStatusBadge status={claimStatus} />
            </div>
            <Text>
              <Locale>Claim type</Locale>
            </Text>
            <div styleName="type-badge">
              <ClaimTypeBadge claimType={claimType} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

ResultHeader.propTypes = {
  name: PropTypes.string,
  labelForId: PropTypes.string,
  id: PropTypes.string, // tracking code or shop order id
  lastEvent: PropTypes.object,
  link: PropTypes.string,
  showClaimedTag: PropTypes.bool,
  claimStatus: PropTypes.string,
  claimType: PropTypes.string,
  children: PropTypes.node,
};

export default React.memo(ResultHeader);
