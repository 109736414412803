import jwtDecode from 'jwt-decode';
import slugify from 'slugify';

export function heapIdentify(token) {
  if (!window.heap) {
    return;
  }

  const { sub: uuid } = jwtDecode(token);
  try {
    window.heap.identify(uuid);
  } catch (e) {
    console.error(e);
  }
}

export function heapResetIdentity() {
  if (!window.heap) {
    return;
  }

  try {
    window.heap.resetIdentity();
  } catch (e) {
    console.error(e);
  }
}

export function heapAddUserProperties(properties) {
  if (!window.heap) {
    return;
  }

  const { accountId, id: userId, accountName, shops = [], token } = properties;

  const { username: email = '' } = jwtDecode(token);
  const fromSevenSenders = email.endsWith('sevensenders.com');

  try {
    window.heap.addUserProperties({
      accountName,
      accountId,
      userId,
      fromSevenSenders,
    });
  } catch (e) {
    console.error(e);
  }
}

export function generateHeapId(string) {
  if (!window.heap) {
    return string;
  }

  return slugify(string, { lower: true });
}

export function heapAddEventProperties(properties) {
  if (!window.heap) {
    return;
  }

  window.heap.addEventProperties(properties);
}

export function heapTrack(eventName, payload) {
  if (!window.heap) {
    return;
  }

  window.heap.track(eventName, payload);
}
