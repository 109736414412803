import React from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';

import container from '../../container';
import LoginPage from '../login/LoginPage';
import Error404 from '../error-pages/Error404';
import HomePage from '../homepage/HomePage';
import AuthRoot from '../../containers/AuthRoot';
import ForgotPasswordPage from '../forgotpassword/ForgotPasswordPage';
import ResetPasswordPage from '../resetpassword/ResetPasswordPage';
import UserSettingsPage from '../UserSettingsPage';

import AnalyticsRoutes from './AnalyticsRoutes';
import TrackingRoutes from './TrackingRoutes';
import SuspendedCustomerNotificationsRoutes from './SuspendedCustomerNotificationsRoutes';
import ShopSettingsRoutes from './ShopSettingsRoutes';
import ContentLibraryRoutes from './ContentLibraryRoutes';
import ParcelFinderRoutes from './ParcelFinderRoutes';
import UserManagementPage from '../settings/user-management/UserManagementPage';
import ReturnsRoutes from './ReturnsRoutes';
import CommsCenterRoutes from './CommsCenterRoutes';
import ChatbotRoutes from './ChatbotRoutes';

const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: (state) => !!state.auth.token,
  wrapperDisplayName: 'UserIsAuthenticated',
});

const locationHelper = locationHelperBuilder({});

const userIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: (state, props) => locationHelper.getRedirectQueryParam(props) || '/home',
  allowRedirectBack: false,
  authenticatedSelector: (state) => !state.auth.token,
  wrapperDisplayName: 'UserIsNotAuthenticated',
});

export const AppRoutes = () => (
  <Router history={container.history}>
    <Switch>
      <Redirect exact from="/" to="/login" />
      <Route exact path="/login" component={userIsNotAuthenticated(LoginPage)} />
      <Route exact path="/forgot-password" component={ForgotPasswordPage} />
      <Route exact path="/reset-password" component={ResetPasswordPage} />
      <Route component={userIsAuthenticated(AuthenticatedRoutes)} />
      <Route component={Error404} />
    </Switch>
  </Router>
);

// All authenticated routes go in here
const AuthenticatedRoutes = () => {
  return (
    <AuthRoot>
      <Switch>
        <Route exact path="/home" component={HomePage} />
        <Route exact path="/settings/user" component={UserSettingsPage} />
        <Route exact path="/settings/user-management" component={UserManagementPage} />
        <Route path="/settings/shop" component={ShopSettingsRoutes} />
        <Route path="/content-library" component={ContentLibraryRoutes} />
        <Route path="/analytics" component={AnalyticsRoutes} />
        <Route path="/customer-notifications" component={SuspendedCustomerNotificationsRoutes} />
        <Route path="/tracking" component={TrackingRoutes} />
        <Route path="/parcel-finder" component={ParcelFinderRoutes} />
        <Route path="/returns" component={ReturnsRoutes} />
        <Route path="/communication-center" component={CommsCenterRoutes} />
        <Route path="/chatbot" component={ChatbotRoutes} />
        <Route component={Error404} />
      </Switch>
    </AuthRoot>
  );
};

export default AppRoutes;
