import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Locale } from '../Translate';
import { generateHeapId } from '../../heap';

import './InstallCodeSelectPanel.scss';

class InstallCodeSelectPanel extends React.PureComponent {
  render() {
    const { children, title, text, onClick, active, disabled } = this.props;

    const styleName = classnames('panel', { active, disabled });

    return (
      <div styleName={styleName} onClick={!disabled ? onClick : null} data-heap-id={generateHeapId(title)}>
        <div styleName="image">{children}</div>
        <h4 styleName="panel-title">
          <Locale>{title}</Locale>
        </h4>
        <span styleName="text">
          <Locale>{text}</Locale>
        </span>
      </div>
    );
  }
}

InstallCodeSelectPanel.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default InstallCodeSelectPanel;
