import { useState, useEffect, useCallback } from 'react';

import container from '../../container';
import { useSelectedShopId } from '../../hooks/useSelectedShop';

function useClaimsCenterOverview() {
  const limit = 25;
  const shopId = useSelectedShopId();

  const [claims, setClaims] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [statuses, setStatuses] = useState([]);
  const [types, setTypes] = useState([]);
  const [shipmentTypes, setShipmentTypes] = useState([]);
  const [sortBy, setSortBy] = useState('createdAt');
  const [sortDir, setSortDir] = useState('DESC');

  const getClaims = useCallback(
    async ({ offset, status, type, sortBy, sortDir, isNon7s }) => {
      setError(null);

      try {
        const { claims, ...data } = await container.touchpointsApi.getClaimsData({
          shopId,
          limit,
          offset,
          status,
          isNon7s,
          type,
          sortBy,
          sortDir,
        });
        setOffset(data.offset);
        setTotal(data.total);

        return claims;
      } catch (err) {
        setError(err);
        setIsLoading(false);
        setIsLoadingMore(false);
      }
    },
    [shopId]
  );

  const getClaimsByOffset = useCallback(
    async (offset = 0) =>
      getClaims({
        offset,
        sortBy,
        sortDir,
        status: statuses,
        type: types,
        isNon7s: shipmentTypes.length === 1 ? shipmentTypes.includes('non-7s') : undefined,
      }),
    [shipmentTypes, statuses, types, sortBy, sortDir, getClaims]
  );

  const loadClaimsFirstPage = useCallback(async () => {
    setIsLoading(true);
    const claims = await getClaimsByOffset();

    if (claims) {
      setClaims(claims);
      setIsLoading(false);
    }
  }, [setClaims, getClaimsByOffset]);

  const reloadClaims = useCallback(async () => {
    const claims = await getClaimsByOffset();

    if (claims) {
      setClaims(claims);
    }
  }, [setClaims, getClaimsByOffset]);

  const loadMore = useCallback(async () => {
    setIsLoadingMore(true);

    const claims = await getClaimsByOffset(offset + limit);

    if (claims) {
      setClaims((prevClaims) => [...prevClaims, ...claims]);
      setIsLoadingMore(false);
    }
  }, [offset, setClaims, setIsLoadingMore, getClaimsByOffset]);

  const applyFilter = useCallback(
    ({ statuses, types, shipmentTypes }) => {
      setStatuses(statuses);
      setTypes(types);
      setShipmentTypes(shipmentTypes);
    },
    [setStatuses, setTypes]
  );

  const clearFilter = useCallback(() => {
    setTypes([]);
    setStatuses([]);
    setShipmentTypes([]);
  }, [setTypes, setStatuses]);

  const setSortOptions = useCallback(
    ({ sortBy, sortDir }) => {
      setSortBy(sortBy);
      setSortDir(sortDir);
    },
    [setSortBy, setSortDir]
  );

  useEffect(() => {
    loadClaimsFirstPage();
  }, [shopId, loadClaimsFirstPage]);

  return {
    claims,
    isLoading,
    isLoadingMore,
    error,
    totalClaimsCount: total,
    statuses,
    shipmentTypes,
    types,
    sortBy,
    sortDir,
    setSortOptions,
    applyFilter,
    clearFilter,
    loadMore,
    reloadClaims,
  };
}

export default useClaimsCenterOverview;
