import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useFlagsStatus } from '@unleash/proxy-client-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ApolloProvider } from '@apollo/client';

import ParcelFinderStartGuide from '../start-guides/ParcelFinderStartGuide';
import ParcelFinderPage from '../parcel-finder/ParcelFinderPage';
import ClaimsCenterPage from '../claims-center/ClaimsCenterPage';
import withShopSettingsRedirect from '../../wrappers/withShopSettingsRedirect';
import ShipmentDetailsPage from '../parcel-finder/ShipmentDetailsPage/ShipmentDetailsPage';
import OrderDetailsPage from '../parcel-finder/OrderDetailsPage/OrderDetailsPage';
import { notificationsClient } from '../../notifications/graphql/notificationsClient';
import ClaimsCenterMaintenancePage from '../claims-center/ClaimsCenterMaintenancePage';
import { withMaintenanceRedirect } from '../../wrappers/withMaintenanceRedirect';
import PageLoader from '../PageLoader';

const withParcelFinderAccess = withShopSettingsRedirect('isClaimsEnabled', '/parcel-finder/start-guide');
const withClaimsCenterMaintenanceRedirect = withMaintenanceRedirect(
  'claims-maintenance',
  '/parcel-finder/claims-center/maintenance'
);
const ClaimsCenterPageWithAccess = withClaimsCenterMaintenanceRedirect(withParcelFinderAccess(ClaimsCenterPage));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
    },
  },
});

function ParcelFinderRoutes() {
  const { flagsReady } = useFlagsStatus();

  // Right now flags are used only within /parcel-finder routes and it's critical for routing to wait for them
  // If there will be more places with flags, we probably should consider moving this logic to a higher level
  // The downside is that higher level means the whole app might need to wait for flags being
  // loaded (which usually is very fast tho). For some places it can be even not that important to wait for flags.
  if (!flagsReady) {
    return <PageLoader />;
  }

  return (
    <ApolloProvider client={notificationsClient}>
      <QueryClientProvider client={queryClient}>
        <Switch>
          <Route exact path="/parcel-finder" component={ParcelFinderPage} />
          <Route exact path="/parcel-finder/claims-center" component={ClaimsCenterPageWithAccess} />
          <Route exact path="/parcel-finder/claims-center/maintenance" component={ClaimsCenterMaintenancePage} />
          <Route exact path="/parcel-finder/start-guide" component={ParcelFinderStartGuide} />
          <Route exact path="/parcel-finder/shipments/:id(\d+)" component={ShipmentDetailsPage} />
          <Route exact path="/parcel-finder/orders/:id(\d+)" component={OrderDetailsPage} />
          <Redirect to="/parcel-finder" />
        </Switch>
      </QueryClientProvider>
    </ApolloProvider>
  );
}

export default ParcelFinderRoutes;
