import React from 'react';

import SettingsPage from './SettingsPage';
import ProxyIframe from '../ProxyIframe';
import SettingsBodyWrapper from './SettingsBodyWrapper';

class ServiceLevelAgreementsPage extends React.PureComponent {
  render() {
    return (
      <SettingsPage {...this.props} title="Service Level Agreement Settings">
        <SettingsBodyWrapper fill>
          <ProxyIframe pageUrl="portal/analytics-carriershipmentcountry/list" />
        </SettingsBodyWrapper>
      </SettingsPage>
    );
  }
}

export default ServiceLevelAgreementsPage;
