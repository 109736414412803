import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import InvestigationStepContainer from './InvestigationStepContainer';
import ColumnsLayout from '../../../common/layout/ColumnsLayout';
import Column from '../../../common/layout/Column';
import BooleanSelect from '../common/BooleanSelect';
import DateInput from '../../../common2/DateInput';
import Uploader from '../../../common/Uploader';
import useStepValidation from '../common/useStepValidation';
import { deliveryInfoValidator } from '../claimValidators';
import ClaimStepClosableMessage from '../common/ClaimStepClosableMessage';
import Locale from '../../../Translate/Locale';
import SidePanelContentHeader from '../../../common2/side-panel/SidePanelContentHeader';
import InputHelpLink from '../InputHelpLink';
import CustomerCommentInput from '../common/CustomerCommentInput';

function InvestigationDeliveryInfoStep(props) {
  const { claim, onChange, onNext, isAllFieldsEditable, ...restProps } = props;
  const { deliveryDate, declarationOfRecipient, isDelivered, isReturnShipment } = claim;
  const isFieldDisabled = !isAllFieldsEditable;
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(false);
  const [errors, onClickNext] = useStepValidation(claim, onNext, deliveryInfoValidator);

  const onInputChange = useCallback((value, name) => onChange({ [name]: value }), [onChange]);

  const onDeliveredChange = useCallback(
    (value) => onChange({ isDelivered: value, ...(!value && { declarationOfRecipient: [], deliveryDate: null }) }),
    [onChange]
  );

  const uploaderHelpLinkElement = (
    <InputHelpLink to="https://support.portal.sevensenders.com/support/solutions/articles/15000048062-submission-document-requirements#Declaration-of-Receipt-(DOR)-Requirements%C2%A0">
      Declaration of receipt requirements
    </InputHelpLink>
  );

  return (
    <InvestigationStepContainer
      {...restProps}
      onNext={onClickNext}
      isDisabled={isNextBtnDisabled}
      isAllFieldsEditable={isAllFieldsEditable}
    >
      <SidePanelContentHeader>Delivery information</SidePanelContentHeader>
      <Column spacing="medium">
        <ColumnsLayout>
          <BooleanSelect
            name="isDelivered"
            label="Delivery successful"
            value={isDelivered}
            isDisabled={isFieldDisabled && !isReturnShipment}
            onChange={onDeliveredChange}
          />
          <DateInput
            name="deliveryDate"
            label="Delivery date"
            placeholder="Add date"
            isRequired
            disabled={(isFieldDisabled && !isReturnShipment) || !isDelivered}
            value={deliveryDate}
            error={errors.deliveryDate}
            onChange={onInputChange}
          />
        </ColumnsLayout>
        <CustomerCommentInput claim={claim} onChange={onInputChange} />
        {!isDelivered ? (
          <ClaimStepClosableMessage intent="success">
            <Locale>CLAIMS_NO_ACTION_REQUIRED_CLICK_NEXT</Locale>
          </ClaimStepClosableMessage>
        ) : (
          <Uploader
            name="declarationOfRecipient"
            label="Declaration of recipient"
            topRightElement={uploaderHelpLinkElement}
            isRequired
            isDisabled={!isDelivered}
            files={declarationOfRecipient}
            errorWithTranslation={errors.declarationOfRecipient}
            allowedFileTypes={['application/pdf', 'image/png', 'image/jpg', 'image/jpeg']}
            helpText="CLAIMS_DECLARATION_OF_RECIPIENT_UPLOAD_HELP_TEXT"
            onChange={onInputChange}
            onBusy={setIsNextBtnDisabled}
          />)}
      </Column>
    </InvestigationStepContainer>
  );
}

InvestigationDeliveryInfoStep.propTypes = {
  shipment: PropTypes.object,
  claim: PropTypes.object,
  isAllFieldsEditable: PropTypes.bool,
  onChange: PropTypes.func,
};

export default React.memo(InvestigationDeliveryInfoStep);
