import i18next from 'i18next';
import Backend from 'i18next-locize-backend';
import logError from '../../utils/logError';

const instance = i18next
  .createInstance()
  .use(Backend)
  .init(
    {
      backend: {
        projectId: '3bdc7216-e30d-4019-8170-9cee7e642f4c',
        apiKey: '9be7d20b-352a-4137-b6a0-7915df6cb6f4',
        referenceLng: 'en',
      },
      fallbackLng: 'en',
      saveMissing: false,
      ns: ['common'],
      defaultNS: 'common',
      // allow keys to be phrases having `:`, `.`
      nsSeparator: false,
      keySeparator: false,
    },
    (err) => {
      if (err) {
        logError(err);
      }
    }
  );

export function getReturnPortalT(language) {
  return new Promise((resolve) => {
    instance.changeLanguage(language, (err) => {
      if (err) {
        logError(err);
      }

      // better to always return t, even if language loading has failed
      // in this case we will have a fallback to fallbackLng (if it was loaded) or just to the raw keys
      resolve(instance.getFixedT(language));
    });
  });
}
