import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Heading4 from '../../common/Heading4';
import Text from '../../common/Text';
import Locale from '../../Translate/Locale';
import Separator from '../return-portal-editor/sections/common/Separator';
import CsvFileSelector from './CsvFileSelector';
import PlainButton from '../../common/PlainButton';
import SubSection from '../return-portal-editor/sections/SubSection';
import { downloadCSV } from './downloadHelpers';
import { groupTranslationsByLanguage, makeCSV, parseCSV } from './csvParser';

import DownloadCsvIcon from '../../../icons/download-csv.svg';

import './ReturnsTranslationsSteps.scss';

const parsingErrors = {
  'empty-data': 'RPE_EMPTY_CSV',
  'wrong-format': 'RPE_WRONG_CSV_FORMAT',
};

function ReturnsTranslationsSteps(props) {
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const { templateTranslations, onChange, onResetTranslations } = props;

  const onFileChange = (file) => {
    setFile(file);
    setError('');
    parseCSV(file)
      .then((data) => onChange(groupTranslationsByLanguage(data)))
      .catch((error) => {
        setError(error);
        onResetTranslations();
      });
  };

  const deleteCsv = () => {
    setFile(null);
    onResetTranslations();
  };

  const onDownloadCSV = () => {
    const data = makeCSV(templateTranslations);
    downloadCSV('template.csv', data);
  };

  return (
    <div styleName="container">
      <div styleName="heading">
        <Heading4>Customize translations</Heading4>
        <Text>
          <Locale>RETURNS_ADD_TRANSLATIONS_DESCRIPTION</Locale>
        </Text>
      </div>
      <div styleName="steps">
        <SubSection title="Step 1" description="RETURNS_DOWNLOAD_CSV_DESCRIPTION">
          <PlainButton icon={DownloadCsvIcon} onClick={onDownloadCSV}>
            Download the CSV file
          </PlainButton>
        </SubSection>
        <Separator />
        <SubSection title="Step 2" description="RETURNS_UPLOAD_CSV_DESCRIPTION">
          <CsvFileSelector onChange={onFileChange} error={parsingErrors[error]} file={file} onRemove={deleteCsv}>
            Upload CSV file
          </CsvFileSelector>
        </SubSection>
      </div>
    </div>
  );
}

ReturnsTranslationsSteps.propTypes = {
  onChange: PropTypes.func,
  onRemoveFile: PropTypes.func,
};

export default React.memo(ReturnsTranslationsSteps);
