import React from 'react';
import AddIcon from '../../icons/addwidget.svg';
import './AddWidget.scss';
import Locale from '../Translate/Locale';

class AddWidget extends React.PureComponent {
  render() {
    return (
      <div styleName="add-widget" onClick={this.props.onClick} data-heap-id="add-wiget">
        <AddIcon styleName="fill" />
        <span styleName="title">
          <Locale>Add new widget</Locale>
        </span>
      </div>
    );
  }
}

export default AddWidget;
