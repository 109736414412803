import { gql, useQuery } from '@apollo/client';

export const getOrderNotificationsLogQuery = gql`
  query orderNotifications($shopId: Int!, $orderId: Int!) {
    orderNotifications(shopId: $shopId, orderId: $orderId) {
      eventTypeId
      sendingLogicId
      eventName
      recipient
      subject
      type
      time
    }
  }
`;

function useOrderNotifications(shopId, orderId) {
  const { data, loading, error } = useQuery(getOrderNotificationsLogQuery, {
    variables: { shopId, orderId },
  });

  const notifications = data?.orderNotifications || [];

  return {
    notifications,
    error,
    loading,
  };
}

export default useOrderNotifications;
