import { gql, useQuery } from '@apollo/client';

export const getShipmentNotificationsLogQuery = gql`
  query shipmentNotifications($shopId: Int!, $shipmentId: Int!) {
    shipmentNotifications(shopId: $shopId, shipmentId: $shipmentId) {
      eventTypeId
      sendingLogicId
      eventName
      recipient
      subject
      type
      time
    }
  }
`;

function useShipmentNotifications(shopId, shipmentId) {
  const { data, loading, error } = useQuery(getShipmentNotificationsLogQuery, {
    variables: { shopId, shipmentId },
  });

  const notifications = data?.shipmentNotifications || [];

  return {
    notifications,
    error,
    loading,
  };
}

export default useShipmentNotifications;
