import { useState } from 'react';

/**
 * We manually maintain history of states because we don't want to have BACK transitions in the state machine:
 *  - having BACK transition for each state is too verbose and error-prone in case of conditional transitions
 *  - state.history can't be used because it remembers only the last step and not the whole history
 *  - history states do not fit because it's logic depends on child-parent state configuration and transitions
 *   (see. https://stately.ai/docs/history-states)
 */

export function useSubmitClaimWorkflow(machine, claim) {
  const [stateValueHistory, setStateValueHistory] = useState([machine.initialState.value]);
  const stateValue = stateValueHistory[stateValueHistory.length - 1];

  const transition = (event) => {
    const nextState = machine.transition(stateValue, event, claim);
    setStateValueHistory([...stateValueHistory, nextState.value]);
  };

  const next = () => transition('NEXT');

  const back = () => {
    if (stateValueHistory.length <= 1) {
      return;
    }

    setStateValueHistory(stateValueHistory.slice(0, -1));
  };

  const workflowStep = stateValue;

  return { workflowStep, transition, next, back };
}
