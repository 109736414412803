import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Locale from '../../Translate/Locale';

import './Badge.scss';

function Badge(props) {
  const { children, intent } = props;

  return (
    <div styleName={classNames('badge', intent)}>
      <Locale>{children}</Locale>
    </div>
  );
}

Badge.propTypes = {
  children: PropTypes.string,
  intent: PropTypes.oneOf(['neutral', 'positive', 'alert', 'warning', 'success']),
};

export default React.memo(Badge);
