import React from 'react';
import PropTypes from 'prop-types';
import Locale from '../../Translate';
import Slider from './Slider';
import './LabeledSlider.scss';

class LabeledSlider extends React.PureComponent {
  state = {
    value: this.props.value
  };

  onChange = value => {
    this.setState({ value })
    this.props.onChange(value);
  };

  render() {
    const {
      onChange,
      showValue,
      children,
      ...props
    } = this.props;

    return (
      <div>
        <div styleName="label">
          <div styleName="label-text">
            <Locale>{children}</Locale>
          </div>
          <div styleName="label-value">
            {this.state.value}
          </div>
        </div>
        <Slider
          onChange={this.onChange}
          {...props}
        />
      </div>
    );
  }
}

LabeledSlider.propTypes = {
  showValue: PropTypes.bool,
};

export default LabeledSlider;