import React from 'react';
import PropTypes from 'prop-types';
import { produce } from 'immer';

import BasicSelect from '../../common2/BasicSelect';
import Text from '../../common/Text';
import { claimFileType } from './claimFileTypeEnum';
import RemoveButton from '../../parcel-finder/claims/common/ItemInfo/RemoveButton';

import './TakeActionFilesList.scss';

const documentTypeOptions = [
  { value: claimFileType.DECLARATION_OF_RECIPIENT, label: 'Declaration of recipient' },
  { value: claimFileType.TRACKING_SCREENSHOT, label: 'Tracking screenshot' },
  { value: claimFileType.INVOICE_DOCUMENT, label: 'Invoice' },
  { value: claimFileType.PICTURE_OF_DAMAGE, label: 'Picture of damage' },
  { value: claimFileType.PICTURE_OF_MISSING_ITEM, label: 'Picture of missing item' },
];

function TakeActionFilesList(props) {
  const { files, errors = {}, onFilesChange } = props;

  const updateFileType = (fileId, fileType) =>
    produce(files, (draftFiles) => {
      const file = draftFiles.find((file) => file.id === fileId);

      if (file) {
        file.fileType = fileType;
      }
    });

  if (!files.length) {
    return null;
  }

  return (
    <div styleName="list" aria-label="Files list">
      <Text ink>File name</Text>
      <Text ink>Document type</Text>
      <div />
      {files.map((file) => (
        <>
          <Text styleName="item">{file.name}</Text>
          <div styleName="item">
            <BasicSelect
              ariaLabel={`${file.name} document type`}
              placeholder="Select..."
              value={file.fileType}
              isInvalid={!!errors[file.id]}
              options={documentTypeOptions}
              onChange={(fileType) => onFilesChange(updateFileType(file.id, fileType))}
              menuPlacement="auto"
              useMenuPortal
            />
          </div>
          <div styleName="item">
            <RemoveButton
              ariaLabel={`${file.name} remove`}
              styleName="item remove-button"
              onClick={() => onFilesChange(files.filter((f) => f.id !== file.id))}
            />
          </div>
        </>
      ))}
    </div>
  );
}

TakeActionFilesList.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      fileType: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  errors: PropTypes.objectOf(PropTypes.string),
  onFilesChange: PropTypes.func,
};

export default React.memo(TakeActionFilesList);
