import useApiAction from '../../../hooks/useApiAction';
import { normalizeClaimData } from '../../../actions/submitClaim';

function useSubmitMethod({ isClaimsAutomated, claim }) {
  const [submitClaim, { isLoading: isSubmitting }] = useApiAction(
    (api) => {
      const submitMethod = isClaimsAutomated ? 'submitClaimAutomated' : 'submitClaim';
      return api[submitMethod](claim.type, normalizeClaimData(claim));
    },
    [isClaimsAutomated, claim],
    {
      feedbackMessage: 'CLAIMS_SUCCESSFULLY_SUBMITTED_NOTICE',
      formatErrorMessage,
    }
  );

  return {
    isSubmitting,
    submitClaim,
  };
}

/* eslint-disable camelcase */
const formatErrorMessage = (error) => {
  const errorMessages = {
    claim_exists_for_shipment_id: 'CLAIM_ALREADY_EXISTS_FOR_SHIPMENT',
    claim_exists_for_tracking_code: 'CLAIM_ALREADY_EXISTS_FOR_TRACKING_CODE',
    claim_files_conflict: 'CLAIM_FILES_CONFLICT',
  };

  return errorMessages[error?.response?.data?.code];
};

export default useSubmitMethod;
