import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Loader from '../../common/Loader';
import Locale from '../../Translate/Locale';
import { generateHeapId } from '../../../heap';

import './Button.scss';

function Button(props) {
  const {
    children,
    size = 'medium',
    intent = 'primary',
    icon: Icon,
    isLoading,
    disabled,
    name,
    ...buttonProps
  } = props;

  const styleName = classNames('button', size, intent, { loading: isLoading, 'has-icon': !!Icon });
  const buttonName = name || generateHeapId(children);

  return (
    <button styleName={styleName} disabled={disabled || isLoading} name={buttonName} {...buttonProps}>
      {isLoading && (
        <span styleName="loader">
          <Loader color="#ffffff" />
        </span>
      )}
      {Icon && <Icon styleName="icon" />}
      <span styleName="text">
        <Locale>{children}</Locale>
      </span>
    </button>
  );
}

Button.propTypes = {
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'large']),
  intent: PropTypes.oneOf(['primary', 'base', 'danger', 'midnight', 'success', 'outline', 'warning']),
  icon: PropTypes.elementType,
  children: PropTypes.string,
  name: PropTypes.string,
};

export default React.memo(Button);
