import Firewall from '../firewall/Firewall';

const initialState = {
  permissionsByShop: {},
  firewallByShop: {},
};

function permissionsReducer(state = initialState, action) {
  if (action.type === 'PERMISSIONS_REQUEST') {
    return {
      ...state,
      loading: true
    }
  }
  if (action.type === 'PERMISSIONS_ERROR') {
    return {
      ...state,
      loading: false,
      error: action.error
    }
  }
  if (action.type === 'PERMISSIONS_RECEIVED') {
    return setPermissions(
      {
        ...state,
        loading: false,
      },
      action.data
    )
  }
  if (action.type === 'LOGOUT') {
    return initialState;
  }
  return state;
}

function setPermissions(state, data) {
  const {
    whiteList,
    blackList,
    shopId
  } = data;

  return {
    ...state,
    permissionsByShop: {
      ...state.permissionsByShop,
      [shopId]: {
        whiteList,
        blackList,
      }
    },
    firewallByShop: {
      ...state.firewallByShop,
      [shopId]: new Firewall(whiteList, blackList),
    }
  }
}

export default permissionsReducer;