import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Loader from '../Loader';
import { Locale } from '../../Translate';
import { generateHeapId } from '../../../heap';

import './Button.scss';

class Button extends React.PureComponent {
  renderLoader() {
    return (
      <div styleName="loader">
        <Loader color="white" />
      </div>
    );
  }

  render() {
    const {
      children,
      small,
      primary,
      success,
      warning,
      danger,
      outline,
      negativeOutline,
      midnight,
      gray,
      innerRef,
      isLoading,
      name,
      ...props
    } = this.props;

    const buttonName = name || generateHeapId(children);

    const styleName = classNames('button', {
      'negative-outline': negativeOutline,
      loading: isLoading,
      small,
      primary,
      success,
      warning,
      danger,
      outline,
      midnight,
      gray,
    });

    return (
      <button ref={innerRef} styleName={styleName} disabled={isLoading} name={buttonName} {...props}>
        {isLoading ? this.renderLoader() : <Locale>{children}</Locale>}
      </button>
    );
  }
}

Button.propTypes = {
  small: PropTypes.bool, // no minimum width and height, ths same vertical and horizontal padding
  primary: PropTypes.bool,
  success: PropTypes.bool,
  warning: PropTypes.bool,
  danger: PropTypes.bool,
  outline: PropTypes.bool,
  children: PropTypes.string,
  name: PropTypes.string,
};

export default Button;
