import { useState, useCallback } from 'react';

import useSubmitMethod from './useSubmitMethod';
import usePermissionAccess from '../../../hooks/usePermissionAccess';
import { CLAIMS_AUTOMATED } from '../../../firewall/permissions';
import { createEmptyInfoItem } from '../../parcel-finder/claims/claimsHelpers';

const initialClaim = {
  isDelivered: true,
  partialDamageInfo: [createEmptyInfoItem()],
  missingItemInfo: [createEmptyInfoItem()],
  weight: 1.0,
};

function useSubmitClaim() {
  const [claim, setClaim] = useState(initialClaim);

  const isAutomatedClaimsEnabled = usePermissionAccess(CLAIMS_AUTOMATED);
  const isClaimsAutomated = isAutomatedClaimsEnabled && !!claim.shipmentId;

  const changeClaim = useCallback(
    (value) => {
      setClaim((prevValue) => ({ ...prevValue, ...value }));
    },
    [setClaim]
  );

  const resetClaim = useCallback(() => {
    setClaim({ ...initialClaim, type: claim?.type });
  }, [claim, setClaim]);

  const { submitClaim, isSubmitting } = useSubmitMethod({ claim, isClaimsAutomated });

  return {
    claim,
    isSubmitting,
    isClaimsAutomated,
    changeClaim,
    resetClaim,
    submitClaim,
  };
}

export default useSubmitClaim;
