import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import Text from '../../common/Text';

import './RichLocale.scss';

function RichLocale(props) {
  const { children, values } = props;

  /**
   * Notes:
   * - we can not use BulletedList and BulletedListItem components here because already translate strings inside
   * - explicit <br> tag instead of white-space: pre-line styles is better because with a complex markup in translation
   *   it (pre-line) might create a lot of unwanted empty lines
   */

  return (
    <Text>
      <Trans
        i18nKey={children}
        components={{
          ul: <ul styleName="list" />,
          li: <li />,
          b: <b />,
          br: <br />,
          em: <em styleName="emphasis" />,
          a: <a styleName="link" target="_blank" />,
        }}
        values={values}
      />
    </Text>
  );
}

RichLocale.propTypes = {
  children: PropTypes.string,
  values: PropTypes.object,
};

export default React.memo(RichLocale);
