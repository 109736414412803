import React from 'react';
import PageContainer from '../pagecontainer/PageContainer';

export const menuItems = [
  {
    id: '/parcel-finder',
    link: '/parcel-finder',
    title: 'Parcel finder',
  },
  {
    id: '/parcel-finder/claims-center',
    link: '/parcel-finder/claims-center',
    matchPaths: ['/parcel-finder/claims-center*'],
    title: 'Claims center',
    shopAccess: 'isClaimsEnabled',
  },
  {
    id: '/analytics/claims/claims-overview',
    link: '/analytics/claims/claims-overview',
    title: 'Claims overview',
    shopAccess: 'isClaimsEnabled',
  },
  {
    id: '/parcel-finder/start-guide',
    link: '/parcel-finder/start-guide',
    title: 'Start guide',
  },
];

class ParcelFinderContainer extends React.PureComponent {
  render() {
    const { children, ...pageProps } = this.props;

    return (
      <PageContainer {...pageProps} menuTitle="Parcel Finder + Claims" menuItems={menuItems} collapsible>
        {children}
      </PageContainer>
    );
  }
}

export default ParcelFinderContainer;
