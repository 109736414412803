import React, { useCallback } from 'react';
import { format } from 'date-fns';

import Input from '../Input';

function DateInput({ onChange, value, ...props }) {
  const date = new Date(value);

  const isValueValid = value && !isNaN(date);
  const formatedValue = isValueValid ? format(value, 'yyyy-MM-dd') : '';

  const onChangeHandler = useCallback((value, name, e) => onChange(new Date(value), name, e), [onChange]);

  return <Input {...props} type="date" value={formatedValue} onChange={onChangeHandler} />;
}

export default React.memo(DateInput);
