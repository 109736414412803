import React, { useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import ReturnsContainer from '../ReturnsContainer';
import ReturnsPageStickyHeader from '../ReturnsPageStickyHeader';
import ReturnsTranslationsSteps from './ReturnsTranslationsSteps';
import ReturnPortalTranslationsPreview from './ReturnPortalTranslationsPreview';
import Button from '../../common2/Button';
import Show from '../../common2/Show';
import Loader from '../../common/Loader';
import useShopReturnPortalsTranslations from './useShopReturnPortalsTranslations';
import useSelectedShop from '../../../hooks/useSelectedShop';
import useUnload from '../../../hooks/useUnload';

import LiveIcon from '../../../icons/check-circle-filled.svg';

import './ReturnsTranslationsPage.scss';

function ReturnsTranslationsPage(props) {
  const { id, isReturnPortalLite: isLite } = useSelectedShop();

  const {
    translations,
    templateTranslations,
    changeTranslations,
    isChanged,
    publishTranslations,
    isPublishing,
    resetTranslations,
  } = useShopReturnPortalsTranslations(id);

  const { t } = props;

  useUnload(isChanged);

  return (
    <ReturnsContainer {...props}>
      <ReturnsPageStickyHeader title="Translations">
        <Button
          intent="success"
          icon={LiveIcon}
          disabled={!isChanged}
          isLoading={isPublishing}
          onClick={publishTranslations}
        >
          Publish translations
        </Button>
      </ReturnsPageStickyHeader>
      <Show when={!!templateTranslations} fallback={<Loader />}>
        <Prompt when={isChanged} message={t('RPTP_EXIT_MODAL_MESSAGE')} />
        <div styleName="content">
          <ReturnsTranslationsSteps
            templateTranslations={templateTranslations}
            onChange={changeTranslations}
            onResetTranslations={resetTranslations}
          />
          <ReturnPortalTranslationsPreview translations={translations} isLite={isLite} />
        </div>
      </Show>
    </ReturnsContainer>
  );
}

export default React.memo(withTranslation()(ReturnsTranslationsPage));
