import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import Heading2 from '../../common/Heading2';
import Drawer from '../../common2/drawer/Drawer';
import DrawerHeader from '../../common2/drawer/DrawerHeader';
import DrawerFooter from '../../common2/drawer/DrawerFooter';
import DrawerBody from '../../common2/drawer/DrawerBody';
import ColumnsLayout from '../../common/layout/ColumnsLayout';
import LabeledValue from '../../common2/LabeledValue/LabeledValue';
import Column from '../../common/layout/Column';
import Select from '../../common2/Select';
import Button from '../../common2/Button';
import SidePanelSection from './SidePanelSection';
import ReturnLabelsList from './ReturnLabelsList';
import { formatDate } from '../cards/dateHelper';

function CreateReturn(props) {
  const {
    isOpen,
    appear,
    onClose,
    onCreate,
    onChangeLabelsCount,
    info: { shopOrderId, deliveryDate, carrier, customer, returnShipmentsLeft = 0 } = {},
    returnShipments = [],
    isLoading,
    labelsCount,
  } = props;

  const options = useMemo(
    () =>
      Array.from({ length: returnShipmentsLeft }).map((_, i) => ({
        value: (i + 1).toString(),
        label: (i + 1).toString(),
      })),
    [returnShipmentsLeft]
  );

  const showLabelsList = returnShipments.length > 0;
  const onChangeLabelsCountHandler = useCallback((value) => onChangeLabelsCount(parseInt(value)), [
    onChangeLabelsCount,
  ]);

  return (
    <Drawer isOpen={isOpen} appear={appear}>
      <DrawerHeader onClose={onClose}>
        <Heading2>CREATE_RETURN_PANEL_TITLE</Heading2>
      </DrawerHeader>
      <DrawerBody>
        <Column spacing="large">
          <SidePanelSection title="Order information">
            <ColumnsLayout>
              <LabeledValue label="Order ID">{shopOrderId}</LabeledValue>
              <LabeledValue label="Delivery date">{formatDate(deliveryDate)}</LabeledValue>
              <LabeledValue label="Carrier">{carrier?.name}</LabeledValue>
              <LabeledValue label="Carrier country">{carrier?.country}</LabeledValue>
            </ColumnsLayout>
          </SidePanelSection>
          <SidePanelSection title="Customer information">
            <ColumnsLayout>
              <LabeledValue label="Recipient name">{customer?.name}</LabeledValue>
              <LabeledValue label="Email">{customer?.email}</LabeledValue>
              <LabeledValue label="Phone">{customer?.phone}</LabeledValue>
            </ColumnsLayout>
          </SidePanelSection>
          <SidePanelSection title="Create a return">
            {showLabelsList ? (
              <ReturnLabelsList shipments={returnShipments} />
            ) : (
              <ColumnsLayout>
                <Select
                  name="labelsCount"
                  label="Number of labels"
                  placeholder="Select an amount..."
                  helpText="RETURN_LABELS_COUNT_SELECT_HELP_TEXT"
                  menuPlacement="auto"
                  useMenuPortal
                  value={labelsCount}
                  options={options}
                  onChange={onChangeLabelsCountHandler}
                  isDisabled={isLoading}
                />
              </ColumnsLayout>
            )}
          </SidePanelSection>
        </Column>
      </DrawerBody>
      <DrawerFooter>
        {!showLabelsList && (
          <Button disabled={!labelsCount} isLoading={isLoading} onClick={onCreate}>
            Create a return
          </Button>
        )}
      </DrawerFooter>
    </Drawer>
  );
}

CreateReturn.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  onChangeLabelsCount: PropTypes.func,
  info: PropTypes.shape({
    shopOrderId: PropTypes.string,
    deliveryDate: PropTypes.string,
    carrier: PropTypes.shape({
      name: PropTypes.string,
      country: PropTypes.string,
    }),
    customer: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    }),
    returnShipmentsLeft: PropTypes.number,
  }),
  returnShipments: PropTypes.array,
  isLoading: PropTypes.bool,
  labelsCount: PropTypes.number,
};

export default React.memo(CreateReturn);
